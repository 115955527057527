import React from 'react';
import axios from 'axios';
import {
    Link, Redirect, Router
  } from 'react-router-dom';
import { Grid } from 'antd-mobile';
import AppHeader from './AppHeader';
import mmph from 'myanmar-phonenumber';

class SimLoginPage extends React.Component {
    constructor(props) {
        super(props)

        //passwordreset
        this.state = { step: "passwordreset", message : "", username: '', form : { phone: '',otpcode: '', password: '', clientTransactionId : 0 }, seconds: 60, isCounting: false, isMobile : 0};
        this.validatePhone      = this.validatePhone.bind(this);
        this.resendOTP           = this.resendOTP.bind(this);
        this.checkValidationApi = this.checkValidationApi.bind(this);
        this.changePasswordApi  = this.changePasswordApi.bind(this);
    }

    startCountdown = () => {
        this.setState({ isCounting: true });
        const intervalId = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.seconds === 0) {
                    clearInterval(intervalId);
                    this.setState({ isCounting: false });
                    return { seconds: 60 }
                }
                return { seconds: prevState.seconds - 1 }
            });
        }, 1000);
    }
    
    handleOtpRequest = () => {
        // Send the phone number to the server to request a new OTP
        // ...
        this.startCountdown();
    }



    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        }
       
    }

    checklogin() {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
    }

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

        }

        // localStorage.setItem("isMobile", "0" );

        this.scheck();

        // var isMobile = /iPhone|iPad|iPod|Android|Macintosh/i.test(navigator.userAgent);
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {

            // window.BPdata.dashboardUrl+"/bg/loginlive"

            localStorage.setItem("isMobile", "1" );

            var d = new Date();
            var day = d.getDay();

            if(localStorage.getItem('httpDate')) {
                if(localStorage.getItem('httpDate') != day) {
                    localStorage.setItem('httpDate', day);
                    localStorage.setItem('httpDateCount', 1);
                }

            } else {
                localStorage.setItem('httpDate', day);
                localStorage.setItem('httpDateCount', 1);
            }


            this.fetchData();

        } 

        // this.fetchData();
        this.hisUrl('/more/login');
    }

    scheck() {
        
        let dataurl = '//api.athapyar22.com/api/v1/cms/player/scheck';
        

        let self = this;

        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-Trident-Token"  : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound,
            // "BP-Time"          : localStorage.getItem(window.BPdata.time)
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        })
        .then(response => {
       

            if(response.data.status_code == 403) {

                let httpDateCount = localStorage.getItem('httpDateCount');

                if(parseInt(httpDateCount) >= 2) {

                    window.location.replace("http://athapyar22.com");

                    httpDateCount = parseInt(httpDateCount)+1;
                    localStorage.setItem('httpDateCount', httpDateCount);

                }
                

             
            }  

            

        })
        .catch(function(error) {
        //console.log(error);
        });
    }

    fetchData() {

        let dataurl = window.BPdata.apiUrlCMSNoS+'/player/hedata';
        
        if(localStorage.getItem("isMobile")) {
            if(localStorage.getItem("isMobile") == "1" ) {
                dataurl = window.BPdata.apiUrlCMSNoS+'/player/hedata';
            }

        }

        // console.log(dataurl);

        let self = this;

        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-Trident-Token"  : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound,
            // "BP-Time"          : localStorage.getItem(window.BPdata.time)
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        })
        .then(response => {
       
            if(response.data.status_code == 200) {
                // form.phone
                
                if(response.data.phone != "") {

                    self.setState({  form : { phone : response.data.phone} });

                } 
                // if(response.data.data.type == "link") {

                
                //     if(response.data.data.token){

                //         self.setState({  message : response.data.data.message, form : { phone : response.data.phone}, step : "profile" });
                    
                //         localStorage.setItem("username", response.data.data.username);
                //         localStorage.setItem("bp-ath-time", response.data.data.token);
    
                //         window.location.replace(response.data.data.weblink);

                //     }
                    

                // }

                // if(response.data.data.type == "login") {

                //     if(response.data.data.token){
                //         self.setState({  form : {
                //             phone : response.data.phone
                //             }, step : "profile"
                //         });
                //     // self.setState({  message : response.data.data.message, form : { phone : response.data.phone}});
                        
                //         localStorage.setItem("username", response.data.data.username);
                //         localStorage.setItem("bp-ath-time", response.data.data.token);
                //     }

                // }
                
                // this.history.push("/more/myaccount");
                

            }        

            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } 

        })
        .catch(function(error) {
        //console.log(error);
        });
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/sign-up');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        //phone : mmph.normalizeInput(this.state.form.phone)
        if(form.phone.length > 6) {
            if(mmph.isValidMMPhoneNumber("0"+form.phone)){
                data = {
                    phone : this.state.form.phone
                }
                this.setState({  message : "SMS Sending" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : window.lang.messages.invalid_phone });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : window.lang.messages.invalid_phone });
        }

    }

    resendOTP() {

        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length >= 6) {
            if(mmph.isValidMMPhoneNumber("0"+form.phone)){
                data = {
                    // first_name : this.state.form.username,
                    phone : this.state.form.phone
                }
                this.setState({  message : "လုပ်ဆောင်နေပါသည်။" });
                this.resendSMSApi(data);
            } else {
                this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
        }
    }

    resendSMSApi(formdata) {
        var self = this;
        // let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
        let dataurl = window.BPdata.apiUrlCMSDual+'/player/sim-login';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound
          },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                self.handleOtpRequest();
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }


    sendSMSApi(formdata) {
        var self = this;
        // let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
        let dataurl = window.BPdata.apiUrlCMSDual+'/player/sim-login';
        
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound
          },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                

                if(response.data.data.type == "login") {

                    self.setState({  message : response.data.data.message , step : "password" });
                    localStorage.setItem("username", response.data.data.username);
                    localStorage.setItem("bp-ath-time", response.data.data.token);

                } 
                else if (response.data.data.type == "link") {
                    self.setState({  message : response.data.data.message , step : "password" });
                    localStorage.setItem("username", response.data.data.username);
                    localStorage.setItem("bp-ath-time", response.data.data.token);

                    window.location.replace(response.data.data.weblink);
                }
                else {
                    self.handleOtpRequest();
                    // self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify", clientTransactionId : response.data.clientTransactionId });

                    self.setState(prevState => ({
                        message : "SMS ပို့ပြီးပါပြီ", step : "verify",
                        form : {
                            ...prevState.form,
                            clientTransactionId : response.data.data.clientTransactionId
                        },
                    }))

                }

                
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    checkValidationApi() {
        let formdata = this.state.form ;
        var self = this;

        // let dataurl = window.BPdata.apiUrl+'/user/verifycode';
        let dataurl = window.BPdata.apiUrlCMS+'/player/verifycode';
        // let dataurl = window.BPdata.apiUrlCMS+'/player/sim-login';
        
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"        : window.BPdata.rand_compound
            },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {


                // if(response.data.data.type == "link") {

                //     self.setState({  message : response.data.data.message , step : "password" });
                //     localStorage.setItem("username", response.data.data.username);
                //     localStorage.setItem("bp-ath-time", response.data.data.token);

                //     window.location.replace(response.data.data.weblink);

                // }

                // if(response.data.data.type == "login") {

                    self.setState({  message : response.data.data.message , step : "password" });
                    localStorage.setItem("username", response.data.data.username);
                    localStorage.setItem("bp-ath-time", response.data.data.token);

                // }

                this.history.push("/more/myaccount");
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }
   
 
    changePasswordApi() {
        if(this.state.form.password.length >= 6) {
            let formdata = this.state.form ;
            var self = this;
            let dataurl = window.BPdata.apiUrlCMS+'/player/pwd';
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'Content-Type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"         : window.BPdata.rand_compound
                },
            })
            .then(function (response) {

                if(response.data.data.status == 403) {
                    self.setState({  message : response.data.data.message });
                } else if(response.data.data.status == 200) {
                    self.setState({  message : response.data.data.message , step : "profile" });
                    // localStorage.setItem(window.BPdata.time, self.state.form.phone );
                    localStorage.setItem(window.BPdata.time, response.data.data.token );
                    localStorage.setItem('username', self.state.form.username );
                }  else {
                    self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
                }

                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        } else {
            this.setState({  message : "စကားဝှက်မှာအနည်းဆုံးအက္ခရာ ၆ လုံးရှိရမည်" });
        }

    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            case "passwordreset": return this.passwordResetBlock();
            case "verify": return this.verifyBlock();
            // case "password": return this.passwordBlock();
            case "profile": return this.profileBlock();
            default:      return "";
          }
    }


    passwordResetBlock() {
        return <div className="form-box">


                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                                <p>
                                    ဆက်လက်ပြုလုပ်ရန်အတွက် ကျေးဇူးပြု၍ <br />တစ်ပတ်လျှင် ၅၅၀ကျပ် ကျသင့်မည့် 
                                    ဆားဗစ်စာရင်းသွင်းခြင်းကို  <br />ATOM ဖုန်းနံပါတ်ဖြင့် အတည်ပြုပါ။<br />
                                    <br />
                                </p>
                                {/* <br /> */}
                                {/* <label>{window.lang.form.phone}</label> */}
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left text-center">
                            <div className="form-group">

                            <div className="PhoneInput">
                                
                                    <select aria-label="Phone number country" className=" text-center grid-20"  disabled>
                                        <option value="95">95</option>
                                    </select>
                                
                                
                                <input type="tel"  autoComplete="tel" error="Phone number required" className="form-control-phone" placeholder="9776000478" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} />
                            </div>
                            
                            {/* <input type="text" autocomplete="tel" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br /> */}
                            </div>
                            <br />
                            <input type="submit" className="btn btn-yellow   pointer grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                            <br />
                            
                        </div>

                    </div>
        </div>
    }
    verifyBlock() {
        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.verify_code} &nbsp;&nbsp;
                                                  {this.state.isCounting && <div>Time remaining: {this.state.seconds} seconds</div>}
                                                  {!this.state.isCounting && <a onClick={this.resendOTP} className="color-red pointer">(Resend OTP)</a>}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center"  name="otpcode" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.otpcode} maxLength="6" /><br />
                            </div>
                            <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.checkValidationApi} /><br />
                        </div>

                    </div>
        </div>
    }

    passwordBlock() {
        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>သင့်ရဲ့ လျှို့၀ှက်နံပါတ် အသစ်ကို ရိုက်ထည့်ပါ။</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                            <input type="password" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /><br />
                            </div>
                            <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.changePasswordApi} /><br />
                        </div>

                    </div>
        </div>
    }

    profileBlock() {
        return <div>
            <div className="row">
                <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
                <p>Welcome to AThaPyar App</p>
                <br />
                {/* <img src="/img/logo.webp" className="medium-logo" /> */}
                <br />
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <AppHeader titleType="1" title={window.lang.form.password_reset} isLoading="false" />
                <div className="page-content text-center">
                    <div className="main-content ">
                        <br />
                        <br />
                        
                        <img src="/img/logo.png" height="150px" />
                        <br />
                        <br />
                        
                        <span className="color-red">{this.state.message}</span>

                        { this.viewHandler() }
                        <br />
                        <br />
                        <div className='grid'>
                        <Link to="/faq" className="cont-wrap-link1 hvr-pulse-shrink1 color-green" >{window.lang.more.faq}</Link>
                        <br />
                        <br />
                        <Link to="/termandconditions" className="cont-wrap-link1 hvr-pulse-shrink1 color-green" >{window.lang.more.term_and_conditions}</Link>
                        </div>
                        <br />
                        
                    </div>
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default SimLoginPage;
